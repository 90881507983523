<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Lendingtree Lead Manager
        </h3>
      </div>
      <button class="btn bg-green" @click="modals.updateCSV = true">
        Update CSV
      </button>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ active: activeTab === 'agent' }" @click="setActiveTab('agent')">
          With Agent
        </li>
        <li :class="{ active: activeTab === 'noAgent' }" @click="setActiveTab('noAgent')">
          Without Agent
        </li>
      </ul>
    </div>
    <div class="controls-panel" v-if="activeTab === 'noAgent'">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 col-md-3 pe-4 ps-3">
              <div class="row">
                <div class="col-3">
                  <button class="btn btn-control p-0"
                          content="Select"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <div class="form-group">
                      <input type="checkbox" name=""
                             class="form-check-input m-0"
                             @click="selectAll(selectAllCheckbox)"
                             v-model="selectAllCheckbox">
                    </div>
                  </button>
                </div>
                <div class="col-3">
                  <button class="btn btn-control"
                          @click="deleteSelected"
                          content="Delete"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <img src="@/assets/icons/bold-icon delete.svg" alt="Delete">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div v-if="activeTab === 'agent'">
        <div class="text-center mt-5" v-if="ui.loading === true">
          <div class="spinner-border mt-5" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-6">
              <div class="search-wrapper">
                <img src="@/assets/icons/icon-search.svg" alt="Icon search">
                <input type="text" placeholder="Search">
              </div>
            </div>
            <div class="col-6">

            </div>
          </div>
          <div class="table">
            <table class="table">
              <thead>
              <tr>
                <th>Agent</th>
                <th>NMLS</th>
                <th>Last updated on</th>
                <th>Last Charged</th>
                <th>Total Due</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Ryan Nohalty</td>
                <td>284418</td>
                <td>Oct, 22 2020 08:10</td>
                <td>$133.00</td>
                <td>$0.00</td>
                <td>
                  <button class="btn btn-primary" @click="modals.changeModal = true">
                    Charge
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-6">
            <div class="search-wrapper">
              <img src="@/assets/icons/icon-search.svg" alt="Icon search">
              <input type="text" placeholder="Search">
            </div>
          </div>
          <div class="col-6">

          </div>
        </div>
        <div class="table">
          <table class="table">
            <thead>
            <tr>
              <th></th>
              <th>Lead Id</th>
              <th>Campaign</th>
              <th>Date</th>
              <th>State</th>
              <th>Product</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Extracted Name</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(lead, index) in unAgentLead" :key="index">
                <td>
                  <div class="form-group">
                    <input type="checkbox"
                           class="form-check-input m-0" @click="checkSelect(lead.isChecked); lead.isChecked = !lead.isChecked" v-model="lead.isChecked">
                  </div>
                </td>
                <td>{{ lead.id }}</td>
                <td>{{ lead.campaign }}</td>
                <td>{{ lead.date }}</td>
                <td>{{ lead.state }}</td>
                <td>{{ lead.product }}</td>
                <td>${{ lead.amount }}</td>
                <td>${{ lead.price }}</td>
                <td>{{ lead.extractedName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <charge-modal v-if="modals.changeModal === true"
                    @close="getLead" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <update-c-s-v-modal v-if="modals.updateCSV === true"
                          @close="modals.updateCSV = false" />
    </transition>
  </div>
</template>

<script>
import ChargeModal from "./modals/ChargeModal";
import UpdateCSVModal from "./modals/UpdateCSVModal";
export default {
  name: "Lendingtree",
  components: {UpdateCSVModal, ChargeModal },
  data() {
    return {
      modals: {
        changeModal: false,
        updateCSV: false
      },
      ui: {
        loading: false
      },
      unAgentLead: [],
      selectAllCheckbox: false,
      activeTab: 'agent'
    }
  },
  methods: {
    getLead() {
      this.modals.changeModal = false;
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
        this.unAgentLead = [
          {id: 45355, campaign: 'Clear Mortgage Capital refi', date: 'Nov, 09 2020', state: 'CA', product: 'Refi', amount: 149.999, price: 24, extractedName: 'Clear Mortgage'}];
        this.unAgentLead.map((el) => {
          el.isChecked = false;
        });
      }, 1000)
    },
    setActiveTab(val) {
      this.activeTab = val;
    },
    deleteSelected() {
      const deleteRequests = this.unAgentLead.filter((el) => {
        return el.isChecked === true;
      });
      if (deleteRequests.length > 0) {
        this.ui.loading = true;
        setTimeout(() => {
          this.ui.loading = false;
          this.selectAllCheckbox = false;
        }, 1000);
      } else {
        alert('Pls select')
      }
    },
    selectAll(val) {
      if (val === false) {
        this.unAgentLead.map((el) => {
          el.isChecked = true;
        });
      } else {
        this.unAgentLead.map((el) => {
          el.isChecked = false;
        });
      }
    },
    checkSelect(val) {
      if (val === false) {
        this.selectAllCheckbox = true;
      } else {
        this.selectAllCheckbox = false;
      }
    }
  },
  created() {
    this.getLead();
  }
}
</script>

<style lang="scss" scoped>
table {
  tbody {
    tr {
      vertical-align: middle;
      td {
        .form-group {
          width: 20px;
          height: 20px;
          .form-check-input {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            &:checked {
              background-color: rgba(0,0,0,1);
              border-color: rgba(0,0,0,1);
            }
          }
        }

      }
    }
  }
}
.form-check-input {
  border-width: 2px;
}

</style>
