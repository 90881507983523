<template>
  <div class="modal modal-center" id="upldateCSV">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-body">
        <div class="modal-title mb-4">
          Update CSV
        </div>
        <file-field :id="1" :multiple="true" :files="files" class="mb-1"/>
        <div class="d-flex mt-4">
          <button class="btn bg-green" @click="updateCSV">
            <span v-if="ui.saving === true"
                 class="spinner-border spinner-border-sm text-light"
                 role="status" aria-hidden="true">
            </span>
            <span class="text-end" v-else>
              Update
            </span>
          </button>
          <button class="btn btn-cancel ms-2" @click="$emit('close')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileField from "../../../components/form/FileField";

export default {
  name: "UpdateCSVModal",
  components: {FileField},
  data() {
    return {
      files: [],
      ui: {
        saving: false
      }
    }
  },
  methods: {
    updateCSV() {
      this.ui.saving = true;
      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
#upldateCSV {
  .modal-block {
    height: 206px;
    max-width: 384px;
    width: 100%;
    border: 0.0625rem solid #efefef;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.07);
    margin: 100px auto;
  }

  .modal-body {
    padding: 30px;

    .modal-title {
      color: black;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.5rem;
    }
  }

}
</style>
